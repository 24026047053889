@use '../../styles/colors';
@use '../../styles/layout';
@use '../../styles/mixins';
@use '../../styles/theme';

.hero {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - #{layout.$navSize});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  .titles {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: theme.$hero-title-color;
    margin-bottom: 50px;

    h1 {
      font: normal normal 52px/56px 'PrimaryFontFamily';
      padding: 0 40px;

      /* Desktop */
      @media only screen and (min-width: layout.$md) {
        font: normal normal 62px/66px 'PrimaryFontFamily';
      }
    }

    h2 {
      font: normal normal 24px/30px 'SecondarySemiBoldFontFamily';
      margin-bottom: 30px;
      padding: 0 40px;

      /* Desktop */
      @media only screen and (min-width: layout.$md) {
        font: normal normal 30px/34px 'SecondarySemiBoldFontFamily';
      }
    }

    .subtitle {
      color: theme.$hero-subtitle-color;
    }
  }

  .ctaLinkContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ctaLink {
    flex-grow: 0;

    button {
      @include mixins.primaryButton();
      background-color: theme.$hero-cta-background-color;
      border-color: theme.$hero-cta-background-color;
      color: theme.$hero-cta-text-color;
    }
  }

  .videoBackground {
    position: relative;
    overflow: hidden;
    width: 100vw;
    height: calc(100vh - #{layout.$navSize});
    background-color: theme.$hero-background-color;

    iframe {
      display: block;
      position: absolute;
      width: calc(100vw * 10);
      height: calc(100vh - #{layout.$navSize});
      top: 50%;
      left: 50%;
      overflow: hidden;
      transform: translate(-50%, -50%);
      pointer-events: none;

      @media (min-aspect-ratio: 16/9) {
        width: 100vw;
        height: calc(100vh * 10);
      }
    }
  }

  .loadingBackground {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: theme.$hero-background-color;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(27, 64, 70, 0.1);
  }
  .heroContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-height: 785px;

    > .image {
      @media screen and (max-width: layout.$sm) {
        display: none;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .right {
      flex: 0 0 50%;

      @media screen and (max-width: layout.$sm) {
        flex: 1 1 100%;
        margin-top: 32px;
      }

      .image {
        margin-bottom: 128px;

        @media screen and (max-width: layout.$sm) {
          margin-bottom: 64px;
        }

        img {
          max-width: 100%;
        }
      }

      .buttons {
        display: flex;

        .button {
          @include mixins.primaryButton();

          margin-right: 10px;
        }
      }
    }
  }
}
