@use '../../styles/colors';
@use '../../styles/theme';
@use '../../styles/layout';

.footer {
  display: 'flex';
  flex-direction: column;
  background-color: theme.$footer-background-color;

  .footerWrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-width: layout.$maxWidth;
    margin: auto;
    padding: 0px 50px 0px 50px;

    .logoColumn {
      display: flex;
      flex: 1 1 20%;
      align-items: center;
      justify-content: center;
      padding: 10px;
    }

    .row {
      display: flex;
      flex-direction: row;
      flex: 1 1 80%;
    }

    .column {
      display: flex;
      flex-direction: column;
      flex: 1 1 15%;
      align-items: center;
      margin-top: 40px;
      padding: 80px 0 10px 0;

      @media (max-width: layout.$lg) {
        flex: 1 1 50%;
        padding-top: 20px;
        margin-top: 20px;
      }

      @media (max-width: layout.$xs) {
        flex: 1 1 100%;
        max-width: layout.$xs;
      }
    }

    .contactColumn {
      flex-basis: 50%;
      margin-left: 30px;

      @media (max-width: layout.$lg) {
        margin-top: 0;
        margin-left: 0;
      }
    }
  }

  .logoImage {
    width: 200px;
    object-fit: contain;

    @media (max-width: layout.$lg) {
      margin-top: 30px;
    }
  }

  .footerItem {
    text-align: center;
  }

  .footerItemRow {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;

    @media (min-width: layout.$lg) {
      &.contactColumn {
        justify-content: left;
      }
    }
  }

  .footerItemLink {
    text-decoration: none;
    align-items: center;
  }

  .contactSocialIconsWrapper {
    flex: 1 1 100%;
    margin-bottom: 20px;
    text-align: center;

    .contactIcon {
      width: 50px;
      height: 50px;
      margin-right: 20px;
    }
  }

  .contactIcon {
    width: 35px;
    display: inline-flex;
    font-size: 20px;
    color: theme.$footer-body-text-color;
  }

  .contactItem {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    text-align: left;

    .bodyText {
      margin-left: 10px;
      margin-bottom: 0;
    }
  }

  .titleText {
    font: normal normal 20px/25px 'SecondarySemiBoldFontFamily';
    color: theme.$footer-title-text-color;
    margin-bottom: 15px;
  }

  .bodyText {
    font: normal normal 300 16px/20px 'SecondaryFontFamily';
    color: theme.$footer-body-text-color;
    margin-bottom: 0;
  }

  a {
    display: inline-flex;
  }

  h2 {
    margin-bottom: 0;
  }

  .aocWrapper {
    .row {
      padding-top: 0;
    }
    .column {
      padding-top: 0;
    }

    @media (max-width: layout.$xs) {
      margin-top: 20px;

      .column {
        margin-top: 0;
      }
      .titleText {
        text-align: center;
      }
    }

    .aocBodyWrapper {
      display: flex;
      flex-direction: row;
      margin-bottom: 20px;

      @media (max-width: layout.$lg) {
        display: block;
      }
    }

    .aocImagesWrapper {
      display: flex;
      flex-direction: row;

      @media (min-width: layout.$lg) {
        padding-left: 20px;
      }

      img {
        &:last-child {
          margin-left: 20px;
        }
      }

      @media (max-width: layout.$lg) {
        img {
          margin-top: 20px;
          &:last-child {
            margin-left: 20px;
          }
        }
      }
    }
  }

  .copyright {
    width: 100%;
    text-align: center;
    padding: 15px;
    background-color: theme.$footer-copyright-background-color;

    span {
      font: normal normal normal 12px/16px 'SecondarySemiBoldFontFamily';
      color: theme.$footer-copyright-text-color;
    }
  }
}
