$xs: 576px;
$sm: 768px;
$md: 992px;
$lg: 1200px;
$xl: 1400px;

$navSize: 90px;

$navZIndex: 10;

$maxWidth: 1200px;
