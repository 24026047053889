@use '../../styles/colors';
@use '../../styles/layout';
@use '../../styles/mixins';

.pageHeader {
  display: flex;
  flex: 1;
  height: 300px;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  @media screen and (min-width: layout.$md) {
    height: 500px;
  }

  .titleContainer {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.3);
    text-align: center;

    h1 {
      font: normal normal 66px/57px 'PrimaryFontFamily';
      color: colors.$white;
      margin-bottom: 0;
      padding: 0 20px;

      @media (max-width: layout.$md) {
        font: normal normal 50px/57px 'PrimaryFontFamily';
      }
    }
  }
}
