@use '../styles/colors';
@use '../styles/layout';
@use '../styles/mixins';
@use '../styles/theme.scss';

.pageHeaderContainer {
  @media screen and (min-width: layout.$sm) and (max-width: layout.$md) {
    background-position: 0 calc(50% - 40px);
  }

  @media screen and (min-width: layout.$lg) {
    background-position: 0 calc(50% - 120px);
  }
}

.wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: colors.$accent-3;
}

.content {
  display: flex;
  flex-direction: column;
  max-width: layout.$maxWidth;
  margin: auto;
  padding: 50px 30px;

  @media screen and (max-width: layout.$md) {
    padding: 30px;
  }

  .textImageWrapper {
    display: flex;
    flex: 1;
    flex-direction: row;
    margin-bottom: 20px;

    @media screen and (max-width: layout.$sm) {
      display: block;
    }

    .textWrapper {
      flex-basis: 55%;
      padding-right: 20px;

      @media screen and (max-width: layout.$sm) {
        padding-left: 0;
      }
    }

    .imageWrapper {
      flex-basis: 45%;
      padding-left: 20px;

      @media screen and (max-width: layout.$sm) {
        padding-left: 0;
        margin: 20px 0;
      }

      img {
        width: 100%;
        height: auto;
        max-height: 500px;

        @media screen and (min-width: layout.$lg) {
          max-height: 400px;
        }
      }
    }
  }

  .title {
    text-align: center;
  }

  h1 {
    font: normal normal bold 44px/57px 'PrimaryFontFamily';
    color: colors.$brand-primary;
    margin-bottom: 25px;
    // word-break: break-all;
  }

  h2 {
    font: normal normal 600 24px/28px 'SecondarySemiBoldFontFamily';
    color: theme.$message-subtitle-color;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  p {
    font: normal normal 300 20px/32px 'SecondaryFontFamily';
    color: theme.$message-body-color;
    text-decoration: none;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .image {
    flex: 1;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  img {
    width: 75%;
    height: auto;
    object-fit: contain;

    @media screen and (max-width: layout.$md) {
      width: 100%;
    }
  }
}

ul > li {
  font: normal normal 300 20px/32px 'SecondaryFontFamily';
  color: colors.$brand-primary;
}

.ctaLinkContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ctaLink {
  flex-grow: 0;

  button {
    @include mixins.primaryButton();
  }
}

.ctaCentered {
  margin-top: 2rem;
  text-align: center;
}

.imageGallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  list-style: none;
  margin-top: 30px;
}

.imageGallery > li {
  flex-basis: 350px;
}

.imageGallery li img {
  cursor: pointer;
  object-fit: cover;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  border-radius: 5px;
}

// .imageGallery::after {
//   content: '';
//   flex-basis: 350px;
// }

:global .pswp__bg {
  background: rgba(15, 15, 15, 0.6) !important;
}
