@use '../../styles/colors';
@use '../../styles/layout';
@use '../../styles/mixins';
@use '../../styles/theme.scss';

.page {
  background-color: colors.$accent-4;
}

.content {
  display: flex;
  flex-direction: column;
  max-width: layout.$maxWidth;
  margin: auto;
  padding: 50px 30px;

  @media (max-width: layout.$md) {
    padding: 30px;
  }

  h1 {
    font: normal normal bold 44px/57px 'PrimaryFontFamily';
    color: colors.$brand-primary;
    margin-bottom: 25px;
    // word-break: break-all;

    @media (max-width: layout.$md) {
      font: normal normal bold 36px/40px 'PrimaryFontFamily';
    }
  }

  h2 {
    font: normal normal 600 24px/28px 'SecondarySemiBoldFontFamily';
    color: theme.$message-subtitle-color;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  p {
    font: normal normal 300 20px/28px 'SecondaryFontFamily';
    color: theme.$message-body-color;
    text-decoration: none;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .ctaLinkContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px;
  }

  .ctaLink {
    flex-grow: 0;

    button {
      @include mixins.primaryButton();
    }
  }

  .imageGallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 10px;
    list-style: none;
    margin-top: 30px;
    margin-left: 0 !important;
    padding-left: 0 !important;
  }

  .imageGallery > li {
    flex-basis: 350px;

    @media (max-width: layout.$md) {
      flex-basis: 100%;
    }
  }

  .imageGallery li img {
    cursor: pointer;
    object-fit: cover;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    border-radius: 5px;
  }

  // .imageGallery::after {
  //   content: '';
  //   flex-basis: 350px;
  // }
}

:global .pswp__bg {
  background: rgba(15, 15, 15, 0.6) !important;
}
