@use './colors';
@use './layout';
@use './theme';

@font-face {
  font-family: 'PrimaryFontFamily';
  src: url(../fonts/PaytoneOne-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'SecondaryFontFamily';
  src: url(../fonts/Montserrat-Regular.woff2) format('woff2'),
    url(../fonts/Montserrat-SemiBold.woff) format('woff');
}

@font-face {
  font-family: 'SecondarySemiBoldFontFamily';
  src: url(../fonts/Montserrat-SemiBold.woff2) format('woff2'),
    url(../fonts/Montserrat-SemiBold.woff) format('woff');
}

html,
body {
  margin: 0;
  padding: 0 !important;
  color: theme.$body-color;
  font-family: 'SecondaryFontFamily';
  background-color: theme.$body-background-color;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

h1 {
  margin-bottom: 22px;
  font: normal normal 400 70px/94px 'PrimaryFontFamily';
  letter-spacing: 0;

  @media screen and (max-width: layout.$sm) {
    margin-bottom: 18px;
    font: normal normal 400 40px/45px 'PrimaryFontFamily';
  }
}

h2 {
  margin-bottom: 22px;
  font: normal normal 700 22px/30px 'PrimaryFontFamily';

  @media screen and (max-width: layout.$sm) {
    margin-bottom: 18px;
    font: normal normal 700 17px/25px 'PrimaryFontFamily';
  }
}

p {
  margin-bottom: 12px;
  font: normal normal 400 22px/30px 'SecondaryFontFamily';

  @media screen and (max-width: layout.$sm) {
    margin-bottom: 10px;
    font: normal normal 400 20px/28px 'SecondaryFontFamily';
  }
}

ul,
ol {
  padding-left: 10px;
  margin-left: 10px;

  li {
    position: relative;
    font: normal normal 400 22px/30px 'SecondaryFontFamily';
    margin-bottom: px;

    @media screen and (max-width: layout.$sm) {
      font: normal normal 400 20px/28px 'SecondaryFontFamily';
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
