@use '../../styles/colors';
@use '../../styles/theme';
@use '../../styles/layout';
@use '../../styles/mixins';

.message {
  .messageContent {
    max-width: 1370px;
    margin: auto;
    padding: 50px 40px;
    text-align: center;

    h1 {
      font: normal normal 30px/36px 'PrimaryFontFamily';
      color: theme.$message-title-color;
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    h2 {
      font: normal normal 600 24px/28px 'SecondarySemiBoldFontFamily';
      color: theme.$message-subtitle-color;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    p {
      font: normal normal 300 20px/32px 'SecondaryFontFamily';
      color: theme.$message-body-color;
      text-align: center;
      text-decoration: none;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .ctaLinkContainer {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .ctaLink {
      flex-grow: 0;

      button {
        @include mixins.primaryButton();
      }
    }

    .partners {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;

      .partner {
        position: relative;
        display: flex;
        flex: 1 1 25%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 16px;
        text-decoration: none;

        @media screen and (max-width: layout.$md) {
          flex: 1 1 50%;
        }

        @media screen and (max-width: layout.$sm) {
          flex: 1 1 100%;
        }

        .icon {
          width: 100%;
          max-width: 262px;
          max-height: 195px;
          margin: 0 auto 40px;
          padding: 16px;
          object-fit: contain;
          object-position: center;
          filter: grayscale(100%);
          transition: filter 250ms ease-in-out;
        }

        .text {
          color: colors.$black;
          font: normal normal bold 20px/27px 'SecondaryFontFamily';
          text-align: center;
          text-decoration: none;
        }

        &:hover {
          .icon {
            filter: grayscale(0%);
          }
        }
      }
    }
  }
}
